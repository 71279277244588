<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fas fa-user-circle"></i> ข้อมูลการสั่งซื้อ
              </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="mb-2 row">
                <label class="col-sm-2 col-form-label label_algin_right">
                  <strong>รหัสลูกค้า :</strong>
                </label>
                <label class="col-sm-10 col-form-label">{{ customer_id }}</label>
              </div>
              <div class="mb-2 row">
                <label class="col-sm-2 col-form-label label_algin_right">
                  <strong>ชื่อลูกค้า :</strong>
                </label>
                <label class="col-sm-10 col-form-label">{{ customer_name }}</label>
              </div>
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                    <tr class="text-center">
                      <th>รหัสการสั่งซื้อ</th>
                      <th>วันที่สั่งซื้อ</th>
                      <th>มูลค่า</th>
                      <th>สถานะสั่งซื้อ</th>
                      <th>สถานะการจ่าย</th>
                      <th>สถานะการจัดส่ง</th>
                      <th>ผู้ติดต่อ</th>
                      <th>สถานที่จัดส่ง</th>
                      <th>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in orders" :key="data.id">
                      <td class="text-center">
                        <a href="javascript:void(0)"class="nav-link text-underline"@click="onViewHandler(data.id)">{{ data.code }}</a>
                      </td>
                      <td>{{ data.date_order }}</td>
                      <td class="text-right">{{ funcs.toDecimalFormat(data.total) }}</td>
                      <td class="text-center">
                        <span class="style_icon_btn">
                          <i :class="StringUtils.getIconOrderStatus(data.status_order)" :title=" StringUtils.getTextOrderStatus(data.status_order).text"></i>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="style_icon_btn">
                          <i :class=" StringUtils.getIconPaymentStatus(data.status_payment)" :title=" StringUtils.getTextPaymentStatus(data.status_payment).text"
></i>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="style_icon_btn">
                          <i
                            :class="
                          StringUtils.getIconDeliveryStatus(
                            data.status_delivery
                          )
                        "
                            :title="
                          StringUtils.getTextDeliveryStatus(
                            data.status_delivery
                          ).text
                        "
                          ></i>
                        </span>
                      </td>
                      <td>{{ data.customer_name }}</td>
                      <td>{{ data.delivery_address }}</td>
                      <td>{{ data.remark }}</td>
                    </tr>
                    <tr v-if="orders.length === 0" class="text-center">
                      <td colspan="9">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card mb-4 mt-4 custom-card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <h3>สถานะการสั่งซื้อ</h3>
                  <div v-for="(v, index) in order_status" :key="`list_order_status_${index}`">
                    <i :class="StringUtils.getIconOrderStatus(v.id)"></i>
                    {{ v.id }} "{{ v.text }}"
                  </div>
                </div>
                <div class="col-md-3">
                  <h3>สถานะการจ่าย</h3>
                  <div v-for="(v, index) in payment_status" :key="`list_payment_status_${index}`">
                    <i :class="StringUtils.getIconPaymentStatus(v.id)"></i>
                    {{ v.id }} "{{ v.text }}"
                  </div>
                </div>
                <div class="col-md-2">
                  <h3>สถานะจัดส่ง</h3>
                  <div v-for="(v, index) in delivery_status" :key="`list_delivery_status_${index}`">
                    <i :class="StringUtils.getIconDeliveryStatus(v.id)"></i>
                    {{ v.id }} "{{ v.text }}"
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row padding_top_20">
            <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
              <select
                class="form-control mb-2"
                id="pageLimit"
                style="width: 80px;"
                v-model="paging.limit"
                v-on:change="changeLimit()"
              >
                <option v-for="data in paging.pages" :key="data" :value="data">{{ data }}</option>
              </select>
            </div>
            <div class="col-lg-11">
              <div class="row g-3" v-if="paging.rowTotal > 0">
                <div class="col-xl-11 col-lg-10 mb-2 text-right" style="margin-top: 30px;">
                  <label
                    class="form-label"
                    style="margin: 8px; font-size: 12px;
                          font-family: Sarabun-Light;
                          color: #818181;"
                  >
                    แสดงรายการที่
                    <strong>
                      {{ (paging.page - 1) * paging.limit + 1 }} ถึง
                      {{ paging.page * paging.limit }}
                    </strong>
                    จาก
                    <strong>{{ paging.rowTotal | numberIntFormat }} รายการ</strong>
                  </label>
                  <paginate
                    v-model="paging.page"
                    :first-last-button="true"
                    :page-count="paging.pageTotal"
                    :page-range="5"
                    :margin-pages="2"
                    :prev-text="'&lt;'"
                    :next-text="'&gt;'"
                    :first-button-text="'&verbar;&lt;'"
                    :last-button-text="'&gt;&verbar;'"
                    :click-handler="changePage"
                    :container-class="
                      'pagination pagination-sm float-right m-auto paging_ul'
                    "
                    :page-class="'page-link'"
                    :prev-class="'page-link prev_paging'"
                    :next-class="'page-link next_paging'"
                  ></paginate>
                </div>
                <div class="col-xl-1 col-lg-2 padding_left_none">
                  <label
                    class="form-label"
                    style="font-size: 12px;
                          font-family: Sarabun-Light;"
                  >Go to page</label>
                  <Select2 v-model="paging.page" :options="paging.pageSizes" />
                </div>
              </div>
            </div>
          </div>
          <div class="float-right">
            <span>
              <router-link :to="'/customer'" class="nav-link text-underline">กลับ</router-link>
            </span>
            <!-- <b-button variant="secondary" class="m-2" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as scripts from "../../../util/scripts";
import config from "../../../util/config";
import mock from "../mock/customer";
import Constants from "../../../util/constants";
import StringUtils from "../../../util/StringUtils";

export default {
  name: "customer-view-history-order",
  data() {
    return {
      permission: {
        appSlug: "customer",
        actionData: {}
      },
      id: "",
      paging: {
        page: 1,
        limit: config.pagination.limit,
        pages: config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      },
      funcs: scripts.funcs,
      mock: mock,
      orders: [],
      status_order: [],
      status_delivery: [],
      status_payment: [],
      customer: {},
      customer_id: "",
      customer_name: "",
      page: 250,
      StringUtils: StringUtils,
      order_status: Constants.order_status,
      payment_status: Constants.payment_status,
      delivery_status: Constants.delivery_status
    };
  },
  computed: {
    getIdFormUrl() {
      return location.href.split("/")[6];
    }
  },
  methods: {
    changeLimit() {
      this.paging.page = 1;
      this.getCustomerHistory();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getCustomerHistory();
    },
    async getCustomerHistory() {
      const params = `customerId=${this.id}&page=${this.paging.page}&limit=${
        this.paging.limit
      }&ascDesc=${"asc"}`;
      const result = await this.HttpServices.postData(
        `/ab_order/getHistoryCustomerById`,
        {
          customerId: this.id,
          page: this.paging.page,
          limit: this.paging.limit,
          ascDesc: "asc"
        }
      );
      if (result && result.status.code == "200") {
        const data = result.data || [];
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.rowTotal = data.rowTotal;
        this.paging.pageTotal = total;
        this.orders = data.data || [];
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.orders = [];
        this.AlertUtils.alertCallback("warning", `ไม่พบข้อมูล Customer`, () => {
          this.$router.push("/customer");
        });
      }
    },
    getLabelStatusOrderName(status) {
      let obj = Constants.order_status.find(row => {
        return row.id == status;
      });
      return obj ? obj.text : "";
    },
    async getStatusOrder() {
      this.status_order = this.mock.status_order;
    },
    async getStatusPayment() {
      this.status_payment = this.mock.status_payment;
    },
    getLabelStatusPaymentName(status) {
      let obj = Constants.payment_status.find(row => {
        return row.id == status;
      });
      return obj ? obj.text : "";
    },
    async getStatusDelivery() {
      this.status_delivery = this.mock.status_delivery;
    },
    getLabelStatusDeliveryName(status) {
      let obj = Constants.delivery_status.find(row => {
        return row.id == status;
      });
      return obj ? obj.text : "";
    },
    onBackHandler() {
      this.$router.push("/customer");
    },
    onViewHandler(id) {
      this.SessionStorageUtils.setSession("page-order", id);
      window.open(`#/order/view/${id}`, '_blank');
      //this.$router.push(`/order/view/${id}`);
    }
  },
  watch: {},
  created() {
    this.getStatusOrder();
    this.getStatusDelivery();
    this.getStatusPayment();
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    this.customer = await this.SessionStorageUtils.getSession("page-customer");
    this.customer = this.customer ? JSON.parse(this.customer) : {};
    this.id = this.customer.id;
    this.customer_id = this.customer.id;
    this.customer_name = this.customer.name;
    this.getCustomerHistory();
  }
};
</script>
